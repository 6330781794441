<template>
  <v-app id="inspire" class="outer" style="background-image: url('../header.jpeg') !important;background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;">
    <v-main>
      <v-container class="fill-height" style="max-width: 74em;">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="4" md="4">
            <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #e11405;">
              <v-card-title style="border-bottom: 1px solid rgba(0,0,0,.125);text-align: center;">
                <v-img
                  lazy-src="@/assets/logolegalpedia.png"
                  src="@/assets/logolegalpedia.png"
                ></v-img>
              </v-card-title>
              <v-card-text class="mt-5">
                  <form>
                    <v-text-field
                      solo
                      v-model="email"
                      :error-messages="errors"
                      label="Email"
                      append-icon="mdi-user"
                      hint="Email"
                      required
                      class="ma-0 pa-0 mb-2 rounded-l"
                      hide-details=true
                      type="email"
                    ></v-text-field>
                    <v-text-field
                        solo
                        v-model="password"
                        :error-messages="errors"
                        label="Password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                        @keyup.enter="submit()"
                        hint="Please input your password"
                        class="border-12"
                        counter
                        required>
                    </v-text-field>
                  </form>
                  <v-row class="">
                    <v-col cols="12">
                      <v-btn block class="rounded-l" color="primary" @click="submit()" style="border-color: deeppink !important;outline-color: deeppink !important;">Login</v-btn>
                    </v-col>
                  </v-row>
                  <div class="d-flex mt-4 ">
                      <!-- <a href="https://svr03.kencana.org/" style="color:#0078d4"><b>Cancel</b></a> -->
                      <v-spacer></v-spacer>
                  </div>                  
                  <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                      <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                      <v-layout column>
                        <div>
                          <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                      </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </v-snackbar>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    
  </v-app>
</template>

<script>


export default {
    name: 'AdminLogin',
    data(){
        return {
            email: '',
            password: '',
            error: true,
            show: false,
            errors: [],
            loading: false,
            snackbar: {
              color: null,
              icon: null,
              mode: null,
              position: "top",
              text: null,
              timeout: 7500,
              title: null,
              visible: false
            },
            timeout: 7500,
            result: ''
        }
    },
    mounted(){
      this.$store.dispatch('setOverlay', false)
    },
    methods: {
        clear(){
          this.email = ''
          this.password = ''
        },
        async submit(){
          this.loading = true
          this.$store.dispatch('setOverlay', true)

          let email = this.email;
          let password = this.password;

          this.$store
          .dispatch("loginUser", { email, password })
          .then(() => {
            this.clear()
            this.loading = false
            this.$store.dispatch('setOverlay', false)

            this.$router.push('/admin/dashboard');
            
            this.snackbar = {
              color: "success",
              icon: "mdi-checkbox-marked-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Success",
              text: "Successfully Login",
              visible: true
            };
          }).catch((err) => {
            console.log(err);
            this.$store.dispatch('setOverlay', false)
            this.loading = false
            this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Error",
              text: err.response,
              visible: true
            };
          });
        }, 
    }
}
</script>