<template>
  <div style="padding-top: 8em;padding-bottom: 8em;">
    <section id="intro-home" class="intro clearfix">
      <div class="container pb-0 mb-0" style="margin-top: 50px !important;">
        <div class="row justify-content-center align-self-center align-items-center">
            <div class="overlay-green"></div>
            <div class="col-lg-7 col-md-5 intro-info text-white pb-0">
              <v-carousel
                cycle
                :show-arrows="false"
                hide-delimiter-background
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(slide, i) in slides"
                  :key="i"
                  reverse-transition="fade-transition"
                  transition="slide-x-transition"
                >
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <div class="item">
                        <h1 class="intro-title">{{ slide.title }}</h1>
                        <p class="mb-5 intro-description">
                          {{ slide.subtitle }}
                        </p>
                        <div>
                            <a href="#ourService" class="btn btn-light btn-get-started">Start Now</a>
                        </div>
                    </div>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="col-lg-5 col-md-7 text-right pb-0" id="intro-absolute" style="z-index: 1;">
                <picture>
                    <!-- <source srcset="lawyer.webp" type="image/webp">
                    <source srcset="lawyer.png" type="image/png"> -->
                    <img src="@/assets/9.png" style="height:auto;width:100%;" class="mx-auto" alt="Lawyer">
                </picture>
            </div>
        </div>
      </div>
    </section>
    <section >
      <v-container class="p-4">
        <v-row>
          <v-col cols="12">
            <h5 class="font-open-sans text-dark mb-4 mt-4 bold">
              Layanan
            </h5>
            <v-divider></v-divider>

          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col class="col-6" md="3"  v-for="(service, i) in services" :key="i">
            <v-card color="#fff" class="p-2">
              <div class="px-2 py-2">
                <router-link :to="service.link" class="d-flex items-center">
                  <v-icon class="w-8 h-8 mr-2" color="primary">
                    {{ service.icon }}
                  </v-icon>
                  <div class="text-gray-800 mt-1 font-12">
                    {{ service.title }}
                  </div>
                </router-link>
              </div>
            </v-card>
            
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-divider></v-divider>
 
    <section id="about" class=" pb-0">
      <v-container class="p-5">
        <v-row>
          <div class="col-lg-4 col-md-6 mb-4">
            <div class="" style="position: relative;" v-if="about">
              <img v-if="about.image == null" class="br-8 img-fluid" src="../assets/default.png" data-src="../assets/default.png" data-srcset="../assets/default.png 500w, ../assets/default.png 400w" style="box-shadow:0px 10px 30px -1px rgba(41, 35, 88, 0.45) !important" srcset="../assets/default.png 500w, ../assets/default.png 400w">
              <img v-else class="br-8 img-fluid" :src="about.image" data-src="../assets/default.png" data-srcset="../assets/default.png 500w, ../assets/default.png 400w" style="box-shadow:0px 10px 30px -1px rgba(41, 35, 88, 0.45) !important" srcset="../assets/default.png 500w, ../assets/default.png 400w">
              <a href="#" data-toggle="modal" data-target="#video" class="play-button" @click="dialog = true">
                  <img src="../assets/youtube-play.png" class="img-fluid" style="box-shadow:none" alt="LegalPedia">
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="about-content pl-md-3 pt-0">
                <h3 class="font-open-sans text-dark mb-4 bold">
                    Tentang LEGALPEDIA
                </h3>
                <p class="text-left mb-0 text-muted">
                  {{ about ? about.description : '' }}
                </p>
            </div>
            <div class="col-md-12">
                <div class="row counters">
                    <div class="col-sm-4 col-4 text-left text-md-center p-0">
                        <span data-toggle="counter-up text-dark">9</span>
                        <p class="mb-2 mb-sm-0">Tahun Pengalaman</p>
                    </div>
                    <div class="col-sm-4 col-4 text-left text-md-center p-0">
                        <span data-toggle="counter-up" style="display:inline-block;">30</span>
                        <span style="display:inline-block;">+</span>
                        <p class="mb-2 mb-sm-0">Lokasi Virtual Office</p>
                    </div>
                    <div class="col-sm-4 col-4 text-left text-md-center p-0">
                        <span data-toggle="counter-up" style="display:inline-block;">500</span>
                        <span style="display:inline-block;">+</span>
                        <p class="mb-2 mb-sm-0">Klien</p>
                    </div>
                </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </section>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card class="rounded-l" color="#fff">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialog = false]"
                        >
                            <v-icon color="#e83e8c">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <div v-html="about.value" v-if="about">
          </div>
          <iframe v-else width="100%" height="315" src="https://www.youtube.com/embed/eHPc-aQiZGQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
    <section id="fitur" class="pt-12 pb-0">
      <v-container class="p-2">
        <v-row>
          <v-col cols="12">
            <h3 class="font-open-sans text-center text-dark mb-4 bold">
              FITUR
            </h3>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col class="col-6" md="3"   v-for="(feature, i) in features" :key="i">
            <v-card width="320" color="#fff">
                <v-card-title class="text-center">
                  <v-icon x-large class="text-center mx-auto">
                    {{ feature.icon }}
                  </v-icon>
                    <!-- <v-img height="200px" contain :src="feature.image"></v-img> -->
                </v-card-title>
                <v-card-text style="height: 140px;">
                  <h5 class="font-open-sans text-center text-dark mb-4 bold">{{feature.title}}</h5>
                  <p>{{ feature.subtitle }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    :to="feature.url"
                  >
                    Selengkapnya <v-icon>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
          <!-- <v-col class="col-12" md="10">
            <v-sheet
              class="mx-auto"
              max-width="1200"
            >
              <v-slide-group
                multiple
                show-arrows
                center-active
              >
                <v-slide-item
                  v-for="(feature, i) in features"
                  :key="i"
                >
                  <v-card width="320" class="ma-4" color="#fff">
                      <v-card-title class="text-center">
                        <v-icon x-large class="text-center mx-auto">
                          {{ feature.icon }}
                        </v-icon>
                      </v-card-title>
                      <v-card-text style="height: 180px;">
                        <h5 class="font-open-sans text-center text-dark mb-4 bold">{{feature.title}}</h5>
                        <p>{{ feature.subtitle }}</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          :to="feature.url"
                        >
                          Selengkapnya <v-icon>
                            mdi-arrow-right
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col> -->
        </v-row>
      </v-container>
    </section>
    <section id="testimoni" class="pt-12 mt-12 pb-12">
      <v-container class="p-2">
        <v-row>
          <v-col cols="12">
            <h3 class="font-open-sans text-dark mb-4 bold">
              TESTIMONI
            </h3>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col class="col-12" md="12">
            <v-sheet
              class="mx-auto"
              color="#f5f6f8"
            >
              <v-slide-group
                multiple
                show-arrows
              >
                <v-slide-item
                  v-for="(testimoni, i) in testimonies"
                  :key="i"
                >
                  <v-card width="320" class="ma-4 testimonial-item mt-12" color="#fff">
                    <v-card-title class="text-center">
                      <img :src="testimoni.avatar" class="testimonial-img">
                    </v-card-title>
                    <v-card-text style="height: 220px;">
                      <h3 class="text-center mt-3">{{testimoni.name}}</h3>
                      <h4 class="text-center mb-4">{{testimoni.company_name}}</h4>
                      <p class="mb-4">
                          "{{testimoni.description}}"
                          <br>
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon x-large color="primary" class="mr-0">
                        mdi-comma
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="article" class="pt-12 pb-0">
      <v-container class="p-2 container">
        <v-row>
          <v-col cols="12">
            <h3 class="font-open-sans text-center text-dark mb-4 bold">
              Artikel LEGALPEDIA
            </h3>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col class="col-12" md="4" v-for="(article, i) in articles.slice(0, 2)"
                  :key="i">
            <v-card
              :loading="loading"
              class="mx-auto"
              max-height="610px"
              min-height="610px"
            >
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                :src="article.image"
              ></v-img>

              <v-card-title class="bold">{{ article.title }}</v-card-title>
              <v-card-subtitle>{{ article.subtitle }}</v-card-subtitle>
              <v-card-text style="height: 135px;">
                <div v-html="article.description.substr(0, 150)">
                </div> ...
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary lighten-2"
                  class="bold"
                  text
                  :to="'/article/'+article.slug+'/detail'"
                >
                  Read More
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col class="col-12" md="4">
            <v-list>
              <div v-for="(article, i) in articles.slice(0, 6)"
                  :key="i">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="bold h5" style="font-size: 1.1rem;white-space: normal;">{{ article.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ article.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-2 hr-line"></v-divider>
              </div>
              <v-list-item to="/article">
                <v-list-item-title class="bold h5" style="font-size: 1.1rem;white-space: normal;color: #e11405;">Lihat Artikel Lain <v-icon color="primary">
                  mdi-arrow-right
                </v-icon></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <contact-us></contact-us>
    <section id="supported" class="pt-12 pb-0">
      <v-container class="p-2 container">
        <v-row>
          <v-col cols="12" class="mb-0">
            <h5 class="font-open-sans text-center text-dark mb-0 bold">
              SUPPORTED BY
            </h5>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-sheet
              class="mx-auto mt-1"
              color="#fff"
            >
              <v-slide-group
                multiple
                :show-arrows="false"
              >
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/bca.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/consultant.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/voffice.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/union.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/maybank.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/mdec.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="p-2 container">
        <v-row>
          <v-col cols="12" class="mb-0">
            <h5 class="font-open-sans text-center text-dark mb-0 bold">
              FEATURED ON
            </h5>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-sheet
              class="mx-auto mt-1"
              color="#fff"
            >
              <v-slide-group
                multiple
                :show-arrows="false"
              >
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/swa.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/pasardana.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/sumselupdate.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/investor.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/detik.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="p-2 container">
        <v-row>
          <v-col cols="12" class="mb-0">
            <h5 class="font-open-sans text-center text-dark mb-0 bold">
              OUR CLIENTS
            </h5>
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-sheet
              class="mx-auto mt-1"
              color="#fff"
            >
              <v-slide-group
                multiple
                :show-arrows="false"
              >
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/eggboss.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/urbino.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/eagle.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/mastrada.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/memiles.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/victory.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card width="240" class="ma-2 p-2 testimonial-item text-center" color="#fff" flat>
                    <img src="../assets/ilmu.png" class="img-fluid mx-auto">
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {testimoni_api} from "@/backend-api/testimoni_api"
import {article_api} from "@/backend-api/article_api"
import {appl_constant_api} from "@/backend-api/appl_constant_api"
import ContactUs from "@/components/layout_front/ContactUs.vue"

export default {
  components: {
    ContactUs
  },
  data: () => ({
    slides: [],
    services: [
      {
        icon: 'mdi-office-building-marker-outline',
        title: 'Izin UMKM',
        link: '/izin-umkm'
      },
      {
        icon: 'mdi-domain',
        title: 'Pendirian PT',
        link: '/pendirian-pt'
      },
      {
        icon: 'mdi-office-building',
        title: 'Pendirian PT Perorangan',
        link: '/pendirian-pt-perorangan'
      },
      {
        icon: 'mdi-toolbox-outline',
        title: 'Layanan OSS RBA',
        link: '/layanan-oss-rba'
      },
      {
        icon: 'mdi-hospital-building',
        title: 'Pendirian CV',
        link: '/pendirian-cv'
      },
      {
        icon: 'mdi-close',
        title: 'Penutupan Perusahaan',
        link: '/penutupan-perusahaan'
      },
      {
        icon: 'mdi-file',
        title: 'Pembuatan dan Perubahan Akta',
        link: '/pembuatan-perubahan-akta'
      },
      {
        icon: 'mdi-text-box-check',
        title: 'Pendaftaran Merek',
        link: '/pendaftaran-merek'
      },
      {
        icon: 'mdi-book-education',
        title: 'Konsultasi Hukum',
        link: '/konsultasi-hukum'
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Kontraktor dan Arsitek',
        link: '/kontraktor-dan-arsitek'
      }
    ],
    features: [
      {
        icon: 'mdi-magnify',
        title: 'Pengecekan Nama PT',
        subtitle: 'Cek Ketersediaan Nama PT Anda disini',
        url: 'cek-nama-pt'
      },
      {
        icon: 'mdi-feature-search',
        title: 'KBLI',
        subtitle: 'Cek Panduan KBLI untuk pemilihan bidang usaha di NIB',
        url: 'kbli'

      },
      {
        icon: 'mdi-text-box-search',
        title: 'Tracking Perizinan ',
        subtitle: 'Cek track perijinan anda disini',
        url: 'tracking-izin'

      },
    ],
    dialog: false,
    loading: false,
    testimonies: [],
    articles: [],
    about: null
  }),
  methods:{
    async getTestimoni(){
      var respData = await testimoni_api.front(`?status=1`, null, false, false, false)
      if (respData.status === 200) {
        this.testimonies = respData.data.data ? respData.data.data : []
      } 
    },
    async getArticle(){
      var respData = await article_api.front(`?status=1`, null, false, false, false)
      if (respData.status === 200) {
        this.articles = respData.data.data ? respData.data.data : []
      } 
    },
    async getSliderText(){
      this.slides = []
      var respData = await appl_constant_api.index(`?key_code=HEADERSLIDER`, null, false, false, false)
      if (respData.status === 200) {
        for (let index = 0; index < respData.data.data.length; index++) {
          const element = respData.data.data[index];
          this.slides.push({
            title : element.title,
            subtitle : element.description
          })
        }
      } 
    },
    async getAboutUs(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGABOUTUS`, null, false, false, false)
      if (respData.status === 200) {
        this.about = respData.data.data ? respData.data.data[0] : null
      } 
    },
  },
  async mounted() { 
    this.$store.dispatch('setOverlay', true)
    await this.getTestimoni()
    await this.getArticle()
    await this.getSliderText()
    await this.getAboutUs()
    this.$store.dispatch('setOverlay', false)
  },
  watch: {
  }
}
</script>
  
<style scoped>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>