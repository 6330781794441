<template>
    <v-app>
        <!-- <sidebar /> -->
        <navigation-header-default />
        <dashboard-content></dashboard-content>
        <a :href="whatsapp ? whatsapp.value : '/'" class="whatsapp-button" id="floatButton" target="_blank">
            <v-icon large color="#fff">
                mdi-whatsapp
            </v-icon>
        </a>
        <content-footer />
    </v-app>
</template>

<script>
import Sidebar from './Partials/Sidebar.vue'
import NavigationHeaderDefault from './Partials/NavigationHeaderDefault.vue'
import DashboardContent from './Content.vue'
import ContentFooter from './Partials/ContentFooter.vue'
import {appl_constant_api} from "@/backend-api/appl_constant_api"

export default {
    name:'DefaultLayout',
    components:{
        NavigationHeaderDefault,
        DashboardContent,
        Sidebar,
        ContentFooter
    },
    data() {
        return {
            whatsapp: null
        }
    },
    async mounted(){
        await this.getWhatsapp()
    },
    methods: {
        async getWhatsapp(){
            var respData = await appl_constant_api.index(`?key_code=CONFIGWHATSAPP`, null, false, false, false)
            if (respData.status === 200) {
                this.whatsapp = respData.data.data ? respData.data.data[0] : null
            } 
        },
    }
}
</script>