import {backendApi} from "./backend-api"

export const message_api = (() =>{
    const test = (() => {
        return ""
    })

    const index = ( async (subPath) => {
        var url = '/api/message'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const store = ( async (subPath, reqBody) => {
        var url = '/api/insert-message'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const update_status = ( async (subPath, reqBody) => {
        var url = '/api/message/update_status'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {test, index, store, update_status};

})()