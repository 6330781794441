import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";
import DashboardLayout from "../components/Layouts/Default_Dashboard.vue";
import DefaultLayout from "../components/Layouts/DefaultLayout.vue";
import DefaultLayoutFront from "../components/layout_front/Default_Dashboard.vue";

import Overview from "../views/Overview.vue";
import Profile from "../views/Profile.vue";

import Content from "../components/Layouts/Content.vue";

import PageNotFound from "../views/PageNotFound.vue"


Vue.use(VueRouter);

function checkAuth(to, from, resolve, reject) {
  console.log(store.getters.isUserLoggedIn, store.state.user.isLoggedIn);
  if (store.getters.isUserLoggedIn && store.state.user.isLoggedIn) {
    resolve();
  } else {
    router.push({ path: "/login" });
  }
}

const routes = [
  {
    path: "/",
    component: DefaultLayoutFront,
    redirect: "/",
    children: [
      {
        path: '',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'izin-umkm',
        name: 'IzinUMKM',
        component: () => import("../views/front/IzinUmkm.vue"),
      },
      {
        path: 'pendirian-pt',
        name: 'PendirianPT',
        component: () => import("../views/front/PendirianPT.vue"),
      },
      {
        path: 'pendirian-pt-perorangan',
        name: 'PendirianPTPerorangan',
        component: () => import("../views/front/PendirianPTPerorangan.vue"),
      },
      {
        path: 'layanan-oss-rba',
        name: 'LayananOSSRBA',
        component: () => import("../views/front/LayananOSSRBA.vue"),
      },
      {
        path: 'pendirian-cv',
        name: 'PendirianCV',
        component: () => import("../views/front/PendirianCV.vue"),
      },
      {
        path: 'penutupan-perusahaan',
        name: 'PenutupanPerusahaan',
        component: () => import("../views/front/PenutupanPerusahaan.vue"),
      },
      {
        path: 'pembuatan-perubahan-akta',
        name: 'PembuatanAkta',
        component: () => import("../views/front/PembuatanAkta.vue"),
      },
      {
        path: 'pendaftaran-merek',
        name: 'PendaftaranMerek',
        component: () => import("../views/front/PendaftaranMerek.vue"),
      },
      {
        path: 'konsultasi-hukum',
        name: 'KonsultasiHukum',
        component: () => import("../views/front/KonsultasiHukum.vue"),
      },
      {
        path: 'kontraktor-dan-arsitek',
        name: 'KontraktorArsitek',
        component: () => import("../views/front/KontraktorArsitek.vue"),
      },
      {
        path: 'cek-nama-pt',
        name: 'CekNamaPT',
        component: () => import("../views/front/CekNamaPT.vue"),
      },
      {
        path: 'kbli',
        name: 'Kbli',
        component: () => import("../views/front/Kbli.vue"),
      },
      {
        path: 'tracking-izin',
        name: 'TrackIzin',
        component: () => import("../views/front/TrackIzin.vue"),
      },
      {
        path: "article",
        component: DefaultLayout,
        redirect: "/article",
        children: [
          {
            path: '',
            name: 'IndexArticle',
            component: () => import("../views/front/article/Index.vue"),
          },
          {
            path: ':slug/detail',
            name: 'DetailArticle',
            component: () => import("../views/front/article/Show.vue"),
          },
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/dashboard",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'Overview',
        component: () => import("../views/admin/Overview.vue"),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'nama-pt',
        name: 'CekNamaPT',
        component: () => import("../views/admin/CekNamaPT.vue"),
      },
      {
        path: 'testimoni',
        name: 'Testimoni',
        component: () => import("../views/admin/Testimoni.vue"),
      },
      {
        path: 'article',
        name: 'Article',
        component: () => import("../views/admin/Artikel.vue"),
      },
      {
        path: 'message',
        name: 'message',
        component: () => import("../views/admin/Message.vue"),
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import("../views/admin/Setting.vue"),
      },
    ]
  },

  { path: "*", 
    component: DefaultLayout,
    redirect: "/PageNotFound",
    children: [
      {
        path: 'PageNotFound',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      // return window.scrollTo({ 
      //   top: document.querySelector(to.hash).offsetTop, 
      //   behavior: 'smooth' 
      // })
      return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
    } else {
      return { x: 0, y: 0 }
    }
  }
});

let programmatic = false
;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
  const method = router[methodName]
  router[methodName] = (...args) => {
    programmatic = true
    method.apply(router, args)
  }
})

router.beforeEach((to, from, next) => {
  // name is null for initial load or page reload
  if (from.name === null || programmatic) {
    // triggered bu router.push/go/... call
    // route as usual
    next()
  } else {
    // triggered by user back/forward 
    // do not route
    next(false)
  }
  programmatic = false // clear flag
})

export default router;
