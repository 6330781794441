<template>
    <div>
        <v-system-bar
            color="#fff"
            height="110"
            class="pl-5 pr-5"
            fixed
        >
            <v-toolbar-title color="#000" class="ml-4" style="width: 300px;font-weight: 700;">
                <router-link to="/">
                    <!-- <span class="">LEGALPEDIA</span> -->
                    <v-img class="hidden-md-and-up" src="@/assets/logolegalpedia.png" alt="LegalPedia" width="180"/>
                    <v-img class="hidden-sm-and-down" src="@/assets/logo_miring.png" alt="LegalPedia" width="250"/>
                </router-link>
                <!-- <span class="">Absense Permit</span> -->
            </v-toolbar-title>
            <v-spacer />
            <v-btn class="border-12 mr-3 hidden-sm-and-down p-4" color="#646f79" outlined to="/#contact-us">
                <v-icon color="#000">
                    mdi-phone
                </v-icon>
                Hubungi Kami
            </v-btn>
            <v-btn class="border-12 mr-3 hidden-sm-and-down p-4" color="primary" outlined to="tracking-izin">
                Tracking Perizinan
            </v-btn>
            <v-btn class="border-12 mr-3 hidden-md-and-up" color="primary" outlined to="tracking-izin">
                Tracking Perizinan
            </v-btn>
        </v-system-bar>
        <v-app-bar fixed color="#e11405" dark height="50" outlined style="box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12) !important;">
            <v-tabs class="mx-auto" v-model="keyTab" :value="keyTab" show-arrows color="#fff">
                <v-tab
                    v-for="(tab) in tabs"
                    :key="tab.route"  
                    :to="tab.url"
                    :class="active_tab == tab.route ? 'v-tab--active v-tab' : 'v-tab'"
                >
                    <v-icon class="mr-2">{{ tab.icon }}</v-icon> {{ tab.name }}
                </v-tab>
                <v-tab :class="active_tab == 'layanan' ? 'v-tab--active v-tab' : 'v-tab'" class="white--text" @click="layananShow = true">
                    layanan
                    <v-icon class="ml-2">
                        mdi-chevron-down
                    </v-icon>
                </v-tab>
            </v-tabs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-app-bar>
        <button v-show="layananShow" id="closeMenu" class="" @click="layananShow = false">
            <v-icon color="#fff">mdi-close</v-icon>
        </button>
        <div id="serviceMenu" v-show="layananShow" reverse-transition="fade-transition" transition="scroll-y-transition" style="overflow: auto;">
            <div class="container-fluid" style="padding-bottom: 150px;">
                <div class="row">
                    <div class="col-sm-12">
                        <a href="" class="h6 mb-1 text-muted">Layanan Kami</a>
                        <hr class="mb-3 mt-2">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-3" >
                                <router-link to="/pendirian-pt">
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-domain
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Pendirian Perusahaan</p>
                                           
                                            <router-link to="/pendirian-pt">
                                            <p class="text-muted font-size-12 mb-0" @click="layananShow = false">Pendirian PT</p>
                                            </router-link>
                                            <router-link to="/pendirian-pt-perorangan">
                                            <p class="text-muted font-size-12 mb-0" @click="layananShow = false">Pendirian PT Perorangan</p>
                                            </router-link>
                                            <router-link to="/pendirian-cv">
                                            <p class="text-muted font-size-12 mb-0" @click="layananShow = false">Pendirian CV</p>
                                            </router-link>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/penutupan-perusahaan">
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-office-building-remove
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Penutupan Perusahaan</p>
                                            <p class="text-muted mb-0 font-size-12">Penutupan PT</p>
                                            <p class="text-muted font-size-12 mb-0">Penutupan Penutupan PMA</p>
                                            <p class="text-muted mb-0 font-size-12">Penutupan CV</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/izin-umkm" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-office-building-marker-outline
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p href="" class="font-size-14 bold mb-1">Izin UMKM</p>
                                            <!-- <p class="text-muted font-size-12 mb-0">Perjanjian Kerja</p>
                                            <p class="text-muted font-size-12 mb-0">Perjanjian Investasi</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/layanan-oss-rba" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-toolbox-outline
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p href="" class="font-size-14 bold mb-1">Layanan OSS RBA</p>
                                            <!-- <p class="text-muted font-size-12 mb-0">Perjanjian Kerja</p>
                                            <p class="text-muted font-size-12 mb-0">Perjanjian Investasi</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>

                        <div class="row" @click="layananShow = false">
                            <div class="col-sm-3">
                                <router-link to="/pembuatan-perubahan-akta" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-office-building-cog
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Pembuatan dan Perubahan Dokumen Perusahaan</p>
                                            <p class="mb-0 text-muted font-size-12">Pembuatan Akta
                                            </p>
                                            <p class="mb-0 text-muted font-size-12">Perubahan Akta</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/pendaftaran-merek" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-office-building-plus
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Pendaftaran Merek</p>
                                            <!-- <p class="mb-0 text-muted font-size-12">Pembuatan Akta
                                            </p>
                                            <p class="mb-0 text-muted font-size-12">Perubahan Akta</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/konsultasi-hukum" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-book-education
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Konsultasi Hukum</p>
                                            <!-- <p class="mb-0 text-muted font-size-12">Pembuatan Akta
                                            </p>
                                            <p class="mb-0 text-muted font-size-12">Perubahan Akta</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-sm-3" @click="layananShow = false">
                                <router-link to="/kontraktor-dan-arsitek" >
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <v-icon>
                                                mdi-account-hard-hat
                                            </v-icon>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Kontraktor dan Arsitek</p>
                                            <!-- <p class="mb-0 text-muted font-size-12">Pembuatan Akta
                                            </p>
                                            <p class="mb-0 text-muted font-size-12">Perubahan Akta</p> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                        </div>

                        <!-- <div class="row">
                            <div class="col-sm-3">
                                <a href="perizinan.php">
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <img src="img/svg/icons/icon-42.svg" class="img-card-menu">
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Perizinan Khusus</p>
                                            <p class="mb-0 text-muted font-size-12">Perizinan Perusahaan</p>
                                            <p class="mb-0 text-muted font-size-12">Perizinan Khusus/Sektoral</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-3">
                                <a href="perizinan-usaha.php">
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <img src="img/icons/perizinan-usaha/perizinan-usaha.svg" class="img-card-menu">
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Perizinan Usaha</p>
                                            <p class="mb-0 text-muted font-size-12">OSS</p>
                                            <p class="mb-0 text-muted font-size-12">NIB</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-3">
                                <a href="layanan-lain.php">
                                    <div class="d-flex card-menu">
                                        <div class="w-25 text-center">
                                            <img src="img/svg/icons/icon-46.svg" class="img-card-menu">
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-14 bold mb-1">Layanan Lainnya</p>
                                            <p class="mb-0 text-muted font-size-12">Penerjemah</p>
                                            <p class="mb-0 text-muted font-size-12">Pelaporan LKPM</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div> -->
                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-12">
                                <p class="h5 mb-1 text-muted">Tools</p>
                                <hr class="mb-3 mt-0">
                            </div>
                            <div class="col-sm-3"  @click="layananShow = false">
                                <div class="d-flex card-menu align-items-center">
                                    <div class="w-25 text-center">
                                        <v-icon>
                                            mdi-magnify
                                        </v-icon>
                                    </div>
                                    <div class="w-75">
                                        <router-link to="/cek-nama-pt" class="font-size-14 bold mb-0">
                                            Pengecekan Nama PT
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3" @click="layananShow = false">
                                <div class="d-flex card-menu align-items-center">
                                    <div class="w-25 text-center">
                                        <v-icon>
                                            mdi-feature-search
                                        </v-icon>
                                    </div>
                                    <div class="w-75">
                                        <router-link to="/kbli" class="font-size-14 bold mb-0">
                                            KBLI
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3" @click="layananShow = false">
                                <div class="d-flex card-menu align-items-center">
                                    <div class="w-25 text-center">
                                        <v-icon>
                                            mdi-text-box-search
                                        </v-icon>
                                    </div>
                                    <div class="w-75">
                                        <router-link to="/tracking-izin" class="font-size-14 bold mb-0">
                                            Track Perijinan
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            keyTab: null,
            layananShow: false,
            active_tab : 'dashboard',
            tabs: [
                {
                    name: 'Home',
                    route: 'dashboard',
                    url: '/#intro-home',
                    icon: 'mdi-home'
                },
                {
                    name: 'Tentang Kami',
                    route: 'calender',
                    url: '/#about',
                    icon: ''
                },
                {
                    name: 'Fitur',
                    route: 'fitur',
                    url: '/#fitur',
                    icon: ''
                },
                {
                    name: 'Testimoni',
                    route: 'testimoni',
                    url: '/#testimoni',
                    icon: ''
                },
                {
                    name: 'Artikel',
                    route: 'artikel',
                    url: '/article',
                    icon: ''
                }
            ],
        }
    },
    methods: {
        drawer(){
            return this.$store.commit('SET_DRAWER_ADMIN')
        },
        checkColor(count){
            return count ? 'red' : 'green'
        },
        logout(){
            this.loading = true

            this.$store.dispatch("logoutUser").then(() => {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Logout Sistem",
                    visible: true
                };
                this.loading = false
                this.$router.push('/')
            });
        }
    },
    mounted(){
    }
}
</script>