<template>
  <v-footer bottom padless class="bg-dark">
    <v-container class="container p-3">
      <v-row>
        <v-col
          class="white--text col-12"
          md="3"
        >
          <div class="footer-info">
            <h3><img src="@/assets/logo-light.png" class="img-fluid" style="max-width: 220px;"></h3>
            <p class="text-light">
              <strong>Phone &nbsp; : </strong> {{ phone ? phone.value : '' }}
            </p>
            <p class="text-light mb-3">
              <strong>Email &nbsp;&nbsp; : </strong> {{ email ? email.value : '' }}<br>
            </p>
            <div class="social-links">
              <a :href="facebook ? facebook.value : '/'" class="facebook btn btn-danger" target="_blank"><v-icon class="ma-0" color="#fff" style="font-size: 20px;">mdi-facebook</v-icon></a>
              <a :href="instagram ? instagram.value : '/'" class="instagram btn btn-danger" target="_blank"><v-icon class="ma-0" color="#fff" style="font-size: 20px;">mdi-instagram</v-icon></a>
              <a :href="linkedin ? linkedin.value : '/'" class="linkedin btn btn-danger" target="_blank"><v-icon class="ma-0" color="#fff" style="font-size: 20px;">mdi-linkedin</v-icon></a>
            </div>
          </div>
        </v-col>
        <v-col
          class="white--text col-12"
          md="6"
        >
          <div class="footer-info pl-5 pt-12">
            <h6>Tentang LEGALPEDIA</h6>
            <p class="text-light">
              {{ about ? about.description : '' }}
            </p>
          </div>
        </v-col>
        <v-col
          class="white--text col-12"
          md="3"
        >
          <div class="footer-info pl-5 pt-12">
            <h6>KANTOR</h6>
            <!-- <p class="text-light"><strong>PT Digital Kita Indonesia <br> KOTA MOJOKERTO <br> </strong> Jl. Srikaya No. 8B <br> Kec. Magersari, Kota Mojokerto, Jawa Timur</p> -->
            <p class="text-light"><strong>  KABUPATEN MOJOKERTO <br> </strong>Jl. Raya Terusan No. 346A <br> Kec. Gedek, Kabupaten Mojokerto, Jawa Timur 61351</p>
          </div>
        </v-col>
        <v-col
          class="text-center white--text"
          cols="12"
        >
        {{ new Date().getFullYear() }} — © LEGALPEDIA | PT Digital Kita Indonesia
        </v-col>
        <v-col
          class="text-right white--text"
          cols="12"
        >
        <p class="text-light">Designed and Developed by Made.id</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {appl_constant_api} from "@/backend-api/appl_constant_api"

export default {
  name: "Footer",
  data() {
        return {
          value: 1,
          loading: false,
          snackbar: {
              color: null,
              icon: null,
              mode: null,
              position: "top",
              text: null,
              timeout: 7500,
              title: null,
              visible: false
          },
          timeout: 7500,
          q: '',
          about: null,
          phone: null,
          email: null,
          facebook: null,
          instagram: null,
          linkedin: null,
        }
  },
  async mounted(){
    this.q = this.$route.query.dashboard
    await this.getAboutUs()
    await this.getEmail()
    await this.getPhone()
    await this.getSocialMedia()
    //console.log(this.q)
  },
  methods: {
    async getAboutUs(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGABOUTUS`, null, false, false, false)
      if (respData.status === 200) {
        this.about = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getEmail(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGEMAIL`, null, false, false, false)
      if (respData.status === 200) {
        this.email = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getPhone(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGPHONE`, null, false, false, false)
      if (respData.status === 200) {
        this.phone = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getSocialMedia(){
      var respDataFacebook = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Facebook`, null, false, false, false)
      if (respDataFacebook.status === 200) {
        this.facebook = respDataFacebook.data.data ? respDataFacebook.data.data[0] : null
      } 
      var respDataInstagram = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Instagram`, null, false, false, false)
      if (respDataInstagram.status === 200) {
        this.instagram = respDataInstagram.data.data ? respDataInstagram.data.data[0] : null
      } 
      var respDataLinkedin = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Linkedin`, null, false, false, false)
      if (respDataLinkedin.status === 200) {
        this.linkedin = respDataLinkedin.data.data ? respDataLinkedin.data.data[0] : null
      } 
    },
  }
};
</script>