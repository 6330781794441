<template>
    <div>
        <v-system-bar
            color="#fff"
            height="90"
            class="pl-5 pr-5"
        >
            <v-toolbar-title color="#000" class="ml-4" style="width: 300px;">
                <v-img class="" src="@/assets/logolegalpedia.png" alt="LegalPedia" width="140"/>
            </v-toolbar-title>
            <v-spacer />
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-badge
                    color="green"
                    :content="notifications.length > 0 ? notifications.length : '0'"
                    overlap
                    bordered
                    class="mr-3"
                    >
                        <v-btn v-on="on" class="border-12 hidden-sm-and-down" color="#bdbaba" outlined >
                            <v-icon color="#000">
                                mdi-bell
                            </v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <v-list dense two-line max-width="300">
                    <v-list-item-group
                        color="secondary"
                    >
                        <v-list-item v-for="(data, key) in notifications" :key="key" :to="data.data.notif_url">
                            <v-list-item-icon>
                                <v-icon>mdi-message</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pt-0 pb-0" @click="markRead(data)">
                                <v-list-item-title class="mb-0 pb-0">{{data.data.notif_title}}</v-list-item-title>
                                <v-list-item-subtitle class="font-12 mb-0 pb-0">{{data.data.notif_message}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ data.created_at }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            <v-btn class="border-12 mr-3 hidden-sm-and-down" color="#bdbaba" outlined to="/admin/setting">
                <v-icon color="#000">
                    mdi-cog
                </v-icon>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-toolbar-title class="" v-on="on" style="font-size:0.99rem; cursor:pointer; border: 1px solid #bdbaba; border-radius: 12px;">
                        <v-avatar size="35px" item class="mr-1">
                            <v-img src="/images/default-user.png" alt="Admin"/>
                        </v-avatar>
                        {{$store.state.user.username}}
                        <v-icon class="ml-2">
                            mdi-chevron-down
                        </v-icon>
                    </v-toolbar-title>
                </template>
                <v-list dense>
                    <v-list-item-group
                        color="secondary"
                    >
                        <v-list-item>
                            <v-card class="rounded-l border-12" width="100%">
                                <v-card-text class="p-1">
                                    <div class="d-flex">
                                        <v-avatar size="45px" item class="mr-2">
                                            <v-img src="/images/default-user.png" alt="Admin"/>
                                        </v-avatar>
                                        <p class="mt-3">
                                            {{$store.state.user.username}}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-list-item>
                        <!-- <v-list-item class="mt-2">
                            <v-list-item-icon>
                                <v-icon>mdi-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Profile</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item>
                            <v-btn type="submit" block class="border-12 mr-12 pl-12 pr-12 pt-2 pb-2 mt-3" @click="logout()">Logout</v-btn>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-system-bar>
        <v-app-bar  color="#e11405" dark height="50" outlined style="box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12) !important;">
            <v-tabs v-model="keyTab" :value="keyTab" show-arrows color="#fff">
                <v-tab
                    v-for="(tab) in tabs"
                    :key="tab.route"  
                    :to="tab.url"
                    :class="active_tab == tab.route ? 'v-tab--active v-tab' : 'v-tab'"
                >
                    <v-icon class="mr-2">{{ tab.icon }}</v-icon> {{ tab.name }}
                </v-tab>
            </v-tabs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-app-bar>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            keyTab: null,
            active_tab : 'dashboard',
            tabs: [
                {
                    name: 'Dashboard',
                    route: 'dashboard',
                    url: '/admin/dashboard',
                    icon: 'mdi-home'
                },
                {
                    name: 'Cek Nama PT',
                    route: 'namapt',
                    url: '/admin/nama-pt',
                    icon: 'mdi-domain'
                },
                {
                    name: 'Testimoni',
                    route: 'testimoni',
                    url: '/admin/testimoni',
                    icon: 'mdi-account-convert'
                },
                {
                    name: 'Artikel',
                    route: 'article',
                    url: '/admin/article',
                    icon: 'mdi-file-document'
                },
                {
                    name: 'Messages',
                    route: 'message',
                    url: '/admin/message',
                    icon: 'mdi-message'
                },
            ],
        }
    },
    methods: {
        drawer(){
            return this.$store.commit('SET_DRAWER_ADMIN')
        },
        checkColor(count){
            return count ? 'red' : 'green'
        },
        async markRead(data){
            await axios.get(`${process.env.VUE_APP_URL}/api/notifications/${data.id}`, { 
			headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
		}).then(res => {
                this.$store.dispatch('getNotification')
            })
        },
        logout(){
            this.loading = true

            this.$store.dispatch("logoutUser").then(() => {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Logout Sistem",
                    visible: true
                };
                this.loading = false
                this.$router.push('/')
            });
        }
    },
    computed: mapState([
        'notifications'
    ]),
    mounted(){
        this.$store.dispatch('getNotification')
    },
}
</script>